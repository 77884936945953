@import './css/general.css';

/********************************* 404 ERROR PAGE *************************************/

body {
  background-color: #FFFFFF;
}

.error-page {
  min-height: 100vh;
}
