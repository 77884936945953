
/*************** CSS CUSTOM COLORS & PROPERTIES ***********/

:root {

/******************* Colors ******************/
--ydb-white: #FFFFFF;
--ydb-off-white: #F7F8FA;
--ydb-dark-gray: #484848;
--ydb-maroon: #A0183E;
--ydb-bright-blue: #2CA8FF;
--ydb-medium-blue: #6A85B4;
--ydb-dark-blue: #344767;
--ydb-light-grey: #D3D3D3;


/***************** Font Family ***************/
--ff-primary: "Be Vietnam Pro", sans-serif;
--ff-heading: "Playfair Display", serif;

--bs-font-sans-serif: Montserrat;
--bs-font-monospace: Helvetica Neue, Arial, sans-serif;
--bs-body-font-family: var(--bs-font-sans-serif);

/*********** Font Size ; 1rem = 16px **********/
--fs-h1: 2rem;
--fs-h2: 1.5rem;
--fs-h3: 1.17rem;
--fs-h4: 1rem;
--fs-h5: 0.83rem;
--fs-h6: 0.67rem;
--fs-600: 2.5rem;
--fs-500: 1.5rem;

--text-h1: var(--fs-h1);
--text-h2: var(--fs-h2);
--text-h3: var(--fs-h3);
--text-h4: var(--fs-h4);
--text-h5: var(--fs-h5);
--text-h6: var(--fs-h6);

--text-extra-large: var(--fs-600);
--text-large: var(--fs-h1);
--text-body: var(--fs-h4);
--text-small: var(--fs-h5);

--text-nav: var(--fs-500);
--text-button: var(--fs-h5);

--text-primary-heading: var(--fs-600);
--text-secondary-heading: var(--fs-500);

/****************** Font Weight *****************/
--fw-regular: 300;
--fw-semi-bold: 500;
--fw-bold: 700;

--size-100: .25rem;
--size-200: .50rem;
--size-300: .75rem;
--size-400: 1rem;
--size-500: 2rem;
--size-600: 3rem;
--size-700: 4rem;
--size-800: 5rem;
--size-900: 6rem;

/******************** Gradient *****************/
--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));

}

/**************** HTML & BODY PROPERTIES *****************/

html {
  height: 100%;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
}

.container {
  width: 100%;
}



/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

body {
  margin: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--ydb-maroon);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*************************** Buttons **************************/

.btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-white);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-medium-blue);
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
  box-shadow: 0px 8px 15px #CCCCCC;
}

.btn:hover {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-medium-blue);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-off-white);
  border: 1px solid var(--ydb-medium-blue);
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
  box-shadow: 0px 8px 15px #CCCCCC;
}

.btn-dark-blue {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-white);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-dark-blue);
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
}

.btn-dark-blue:hover {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-dark-blue);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-off-white);
  border: 1px solid var(--ydb-dark-blue);
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
}

.contact-hero-btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-white);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-maroon);
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
  box-shadow: 0px 8px 15px #CCCCCC;
}

.contact-hero-btn:hover {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-maroon);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-off-white);
  border: 1px solid var(--ydb-maroon);
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
  box-shadow: 0px 8px 15px #CCCCCC;
}

.error-404-btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-white);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-maroon);
  border: 1px solid transparent;
  padding: 0.75em 1.5em;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
  box-shadow: 0px 8px 15px #CCCCCC;
}

.error-404-btn:hover {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-maroon);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-off-white);
  border: 1px solid var(--ydb-maroon);
  padding: 0.75em 1.5em;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
  box-shadow: 0px 8px 15px #CCCCCC;
}

.bg-gradient-maroon {
background-image: linear-gradient(to right top, #eb125b, #d81554, #c5174c, #b21845, #a0183e);
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

/******************************** Images ********************************/

.img-fluid {
  max-width: 100%;
  height: auto;
}

/**************************** Border Radius ******************************/

.rounded {
  border-radius: 0.25rem !important;
}

/*************************** Background Colors **************************/

.bg-white {
  background-color: var(--ydb-white);
}

.bg-off-white {
  background-color: var(--ydb-off-white);
}

.bg-maroon {
  background-color: var(--ydb-maroon);
}

/****************************** Font Family *****************************/

.serif-heading {
  font-family: var(--ff-heading);
}

/****************************** Text Colors *****************************/

.text-white {
  color: #FFFFFF !important;
  font-weight: 500;
}

.text-maroon {
  color: var(--ydb-maroon);
  font-weight: 500;
}

.text-dark-grey {
  color: #484848 !important;
  font-weight: 500;
}

.text-medium-blue {
  color: #6A85B4 !important;
  font-weight: 500;
}

.text-dark-blue {
  color: #344767 !important;
  font-weight: 500;
}

.dark-blue-bold-text {
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #344767;
  font-size: 3rem;
  line-height: 1.25;
  letter-spacing: -0.03rem;
}

.text-grey {
  color: #7f8fa6;
  font-weight: 200;
  line-height: 1.625;
  letter-spacing: 0.90px;
  font-size: 20px;
}

/****************************** Text Size *****************************/

.text-size-extra-large {
  font-size: var(--text-extra-large);
}

.text-size-large {
  font-size: var(--text-large);
}

.text-size-body {
  font-size: var(--text-body);
}

.text-size-small {
  font-size: var(--text-small);
}

.text-size-primary-heading {
  font-size: var(--text-primary-heading);
  line-height: 1.25em;
}

.text-size-secondary-heading {
  font-size: var(--text-secondary-heading);
  line-height: 1.10em;
}

.text-size-h1 {
  font-size: var(--text-h1);
}

.text-size-h2 {
  font-size: var(--text-h2);
}

.text-size-h3 {
  font-size: var(--text-h3);
}

.text-size-h4 {
  font-size: var(--text-h4);
}

.text-size-h5 {
  font-size: var(--text-h5);
}

.text-size-h6 {
  font-size: var(--text-h6);
}

.text-size-nav {
  font-size: var(--text-nav);
}

.text-size-button {
  font-size: var(--text-button);
}

/****************************** Borders *****************************/

.border-maroon {
  border-color: #A0183E !important;
}

/****************************** Alignment *****************************/

.text-center {
  text-align: center;
}

/****************************** Badge & Badge Colors *****************************/

.badge {
  display: inline-block;
  padding: 0.55em 0.9em;
  font-size: 0.95em;
  line-height: 1.3;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.45rem;
}

.badge-white {
  background-color: var(--ydb-white);
  color: var(--ydb-dark-blue);
  font-weight: 550;
}

.badge-dark-blue {
  background-color: var(--ydb-dark-blue);
  color: var(--ydb-white);
  font-weight: 550;
}

/****************************** HR (Horizontal Rule) Element *****************************/

hr.vertical {
  position: absolute;
  background-color: transparent;
  height: 80%;
  right: 0;
  top: 0;
  width: 1px;
  background: #344767;
}

/*************************************** Avatar **************************************/

.avatar {
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 0.625rem;
  height: 48px;
  width: 48px;
  transition: all .2s ease-in-out;
}

.avatar-xxl {
  width: 110px !important;
  height: 110px !important;
}

/*********************************** Rounded Circle **************************************/

.rounded-circle {
    border-radius: 50%!important;
}

.icon-shape {
  width: 48px;
  height: 48px;
  background-position: center;
  border-radius: 0.625rem;
}

.border-radius-lg {
  border-radius: 0.625rem;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n7 {
  margin-top: -6rem !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.mb-md-0 {
  margin-bottom: 0 !important;
}

/******************** TEXT PROPERTIES *******************/

.hidden {
  display: none;
}

.bold {
  font-weight: 600;
}

.underline {
  text-decoration: underline;
}

/************************** LOADING SPINNER ******************/

#loading-spinner {
  display: none;
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  background-color: #ffffff;
  width: 85px;
  height: 85px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 3000;
}

#loading-spinner:before {
  content:'';
  width: 85px;
  height: 85px;
  border-radius: 50%;
  border-top: 12px solid var(--ydb-maroon);
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  z-index: 3000;
  top: -10px;
  left: -10px;
  animation: rt 1s linear infinite;
}

#loading-spinner img {
  height: 50px;
  width: 50px;
  text-align: center;
  display: block;
  margin-top: 5px;
  margin-left: 4px;
}

@keyframes rt {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-webkit-keyframes rt {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/***************************** Cards ***********************/

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
}

@media (min-width: 50em) {

  :root {
    --fs-nav: var(--fs-500);
    --fs-primary-heading: var(--fs-600);
    --fs-secondary-heading: var(--fs-700);
    --fs-body: var(--fs-400);
  }

}
